<style >
.serviceRemark{
    min-width: 200px;
    /* height: 50px; */
    padding: 5px;
    border: 1px solid #5cadff;
    border-radius: 3px;
    color: #808695;
    font-weight: 400;

    position: fixed;
    top: 165px;
    background-color: papayawhip;
    z-index: 999;
}
</style>
<template>
  <div>
    <Row :gutter="6" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select
          size="small"
          placeholder="乙方"
          filterable
          clearable
          v-model="query.secondPartyId"
        >
          <i-option
            :value="item.id"
            v-for="item in channellist"
            :key="item.id"
            >{{
              item.name
            }}</i-option
          >
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select
          size="small"
          placeholder="状态"
          clearable

          v-model="query.status"
        >
          <i-option
            v-for="item in statusArray"
            :key="'status_' + item.id"
            :value="item.id"
            >{{ item.name }}</i-option
          >
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker
          size="small"
          placeholder="有效期"
          v-model="schedule"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-input
          size="small"
          placeholder="关键字：支持名称查询"
          v-model="query.keyWord"
        ></i-input>
      </i-col>
      <i-col span="4" class="m-b-5">
        <i-button
          size="small"
          class="m-r-5"
          type="primary"
          icon="ios-search"
          @click="handleSearch()"
          >搜索</i-button
        >
        <Button size="small" type="success" class="m-r-5" @click="handleAdd"
          >创建储值合同</Button
        >
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
    <Modal v-model="contractAddModal" width="1200">
      <p slot="header" class="text-center">
       新增储值合同
      </p>
      <contract-add v-if="contractAddModal" ref="contractAdd"  :onSuccess="addSuccess"></contract-add>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="contractAddModal = false"
          >取消</Button
        >
        <Button type="primary" @click="handleAddDevice">确定</Button>
      </div>
    </Modal>

    <contract-detail ref="contractDetail"></contract-detail>
  </div>
</template>

<script>
import ContractDetail from './ContractDetail.vue'
import ContractAdd from './ContractAdd.vue'
import { ParseDate } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
/** new api */
import { getCompanyChild } from '@/api/os/company'
import { getContractPage, getStatusList, invalidContract } from '@/api/scp/storedContract'
export default {
  components: {
    ContractAdd,
    ContractDetail
  },
  data () {
    return {
      channellist: [],
      statusArray: [],
      schedule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      list: [],
      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '合同名称', align: 'center', key: 'name' },
        { title: '甲方', align: 'center', key: 'publisherName' },
        { title: '乙方', align: 'center', key: 'secondPartyName' },
        {
          title: '储值合同有效期',
          align: 'center',
          key: 'startDate',
          render: (h, data) => {
            return h('span', data.row.startDate + '至' + data.row.endDate)
          }
        },
        {
          title: '合同总额',
          align: 'center',
          key: 'amount',
          render: (h, data) => {
            return h('span', {
              style: {
                color: '#19be6b'
              }
            }, toMoney(data.row.amount))
          }
        },
        {
          title: '库存量',
          align: 'center',
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '库存量'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      // const title = document.createElement('p')
                      // title.innerHTML = '计费时间是指：'
                      // serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '电子媒体库存单位为：秒，其他媒体为：块*天'
                      serviceDiv.appendChild(content)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 3秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 3000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          key: 'storedInventoryList',
          render: (h, params) => {
            const spans = []
            params.row.storedInventoryList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                '('
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                ')'
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人', align: 'center', key: 'createUserName' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return h(
              'span',
              `${params.row.statusName} `
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.contractDetail.showModal(params.row.id)
                    }
                  }
                },
                '详情'
              ),
              params.row.status === 5 ? h(
                'a',
                {
                  style: { marginRight: '5px', color: 'red' },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: '操作提示',
                        content: '是否作废当前储值合同？',
                        onOk: () => {
                          invalidContract({ storedContractId: params.row.id }).then((res) => {
                            if (res && res.errcode === 0) {
                              this.getPage()
                              this.$Notice.success({ desc: '操作成功' })
                            }
                          })
                        }
                      })
                    }
                  }
                },
                '作废'
              ) : null
            ])
          }
        }
      ],
      contractAddModal: false
    }
  },
  created () {
    this.getCompanyArray()
    this.getStatusData()
    this.getPage()
  },
  methods: {
    getCompanyArray () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array

        this.$store.commit('set_storedValue_secondPartyArray', this.channellist)
      })
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getStatusData () {
      getStatusList({}).then((res) => {
        if (res && !res.errcode) {
          this.statusArray = res
        }
      })
    },
    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    // 搜索
    handleSearch () {
      this.query.startDate = this.schedule[0] ? ParseDate(this.schedule[0]) : null
      this.query.endDate = this.schedule[1] ? ParseDate(this.schedule[1]) : null
      this.query.statusList = this.query.status ? JSON.stringify([this.query.status]) : null

      this.query.pageNumber = 1
      this.getPage()
    },
    // 获取分页列表
    getPage () {
      getContractPage(this.query).then((res) => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
        } else {
          this.list = []
          this.total = 0
        }
      })
    },
    handleAdd () {
      this.operate = 1
      this.contractAddModal = true
    },
    handleAddDevice () {
      this.$refs.contractAdd.handleSubmit()
    },
    addSuccess () {
      this.contractAddModal = false
      this.getPage()
    }
  }
}
</script>
